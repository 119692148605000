import { createApp, reactive } from 'vue';
// import Vue from 'vue'
import Notifications from '@kyvg/vue3-notification';
import App from './App';
import router from './router';
import store from './store';
import ajax from './libs/ajax';

// Enable all for tests
localStorage.debug = 'e:*';
localStorage.devMode = 1;

const app = createApp(App);
app.config.globalProperties.$ajax = ajax;

ajax.post('/dictionary/load', { pathname: window.location.pathname }, (err, result) => {
  const dict = result;
  dict.dictionary_loaded = true;
  const globalState = reactive({
    dictionary: dict,
  });
  app.provide('globalState', globalState);

  app.use(store).use(router).use(Notifications).mount('#app');
});
