<template>
  <div v-if="pageState === 'loading'" class="d-flex justify-content-center align-items-center min-vh-100">
    <div class="text-center">
      <h1 v-html="_d('msg_loading_page', '読み込み中...', 'Message informing is loading the page')"></h1>
    </div>
  </div>
  <div v-else-if="pageState === 'processing'" class="d-flex justify-content-center align-items-center min-vh-100">
    <div class="text-center">
        <h1 v-html="_d('msg_processing', '処理中...', 'Message informing is processing')"></h1>
    </div>
  </div>
  <div v-else-if="pageState === 'error'" class="d-flex justify-content-center align-items-center min-vh-100">
    <div class="text-center">
      <h1 v-html="_d('msg_error', 'エラー', 'Message informing about an error')"></h1>
        <div>{{ errorMessage1 }}<br>{{ errorMessage2 }}</div>
    </div>
  </div>
  <div v-else-if="pageState === 'ask_for_password'" class="d-flex justify-content-center align-items-center min-vh-100">
    <div class="text-center">
      <div style="display: flex; justify-content: center; align-items: center;margin-bottom:30px;">
          <img src="/img/logo_v2.png" alt="e-attach" height="202" />
      </div>
        <div style="justify-content: center;">
        <center>
          <span v-html="_d('login_ask_password','ファイルにアクセスするためのパスワードを入力します。','Message at recipient login page')"></span>
          <br>
          <br>
          <input type="password" data-cy="pass-box" class="form-control" id="attach_password" :placeholder="_d('password', 'パスワード', 'password to login')" v-model="attach_password"
          style="max-width: 300px;" autocomplete="off" data-lpignore="true">
          <br>
          <button class="btn btn-primary" data-cy="submit-btn" @click.prevent="onSubmitPassword" v-html="_d('log_in_button', 'ログイン', 'button to login')"></button>
        </center>
        </div>
    </div>
  </div>
  <div v-else-if="pageState === 'ready_to_upload'" class="row" style="margin-top:20px;">
    <div class="row">
      <div class="col-2"></div>
      <div class="col-8">
        <div style="display: flex; justify-content: center; align-items: center;margin-bottom:30px;">
            <img src="/img/logo_h2.png" alt="e-attach" />
        </div>
        <span v-html="_d('upload_message', 'ファイルをアップロードしてください ', 'A upload message to recipient upload the files')" ></span><br>
      </div>
      <div class="col-2"></div>
    </div>  <!-- row -->
    <div class="row" style="margin-top:20px">
      <div class="col-2"></div>
      <div class="col-8">
        <FileDropArea :attach_id="attachKey"  @resultError="checkResultError" :disabled="false" />
      </div>
      <div class="col-2"></div>
    </div>
    <div class="row" style="margin-top:20px">
      <div class="col-2"></div>
      <div class="col-8">
        <button data-cy="logout-btn" class="btn btn-primary" @click.prevent="onLogout">{{_d('bottom_logout_at_upload', 'ログアウト', 'Link on the top of bottom of page.')}}</button>
      <br>
      </div>
      <div class="col-2"></div>
    </div>
  </div>
  <div v-else-if="pageState === 'show_files'" class="row" style="margin-top:20px;">
    <div class="row"  style="margin-top:20px">
      <div class="col-2"></div>
      <div class="col-8">
        <div style="display: flex; justify-content: center; align-items: center;margin-bottom:30px;">
            <img src="/img/logo_h2.png" alt="e-attach" />
        </div>
      </div>  <!-- col-8 -->
    </div> <!-- row -->

    <div class="row" >
      <div class="col-2"></div>
      <div class="col-8">
        <span v-if="listOfFiles.length > 0" v-html="_d('download_message', 'ファイルをダウンロードします: ', 'A download message at top of files ')" ></span><br>
        <table class="table table-striped table-hover table-sm">
            <thead>
              <tr v-if="listOfFiles.length > 0">
                <th style="">{{_d('file_name_at_download_table', 'ファイル名', 'The table header for file name')}}</th>
                <th style="width: 100px;text-align: end;">{{_d('file_size_at_download_table', 'サイズ', 'The table header for file size')}}</th>
              </tr>
              </thead>
            <tr
            @click.prevent="getRedirection(file)"
            class="download_tr" style="font-size: 14px;border-bottom: solid 1px rgb(235, 234, 234);" v-for="file in listOfFiles" :key="file.id">
              <td style="">
                <a href="#" style="color: #6666FF; background-color:#fff0; " :target="file.id">
                {{ fileNameFormat(file.file_display_name) }}
                </a>
              </td>
              <td style="text-align: end;">
                <a href="#" style="color: #6666FF; background-color:#fff0; " :target="file.id">
                {{ formatFileSize(file.file_size) }}
                </a>
              </td>
            </tr>
        </table>
      </div>  <!-- col-8 -->
    </div>  <!-- row -->

    <div v-if="allowUpload === 1" class="row"  style="margin-top:40px">
      <div class="col-2"></div>
      <div class="col-8">
        <span v-if="listOfFiles.length > 0" v-html="_d('upload_message_on_download_page', '共有したいファイルをアップロードすることもできます:', 'アップロードおよびダウンロード コンテンツがある場合、受信者へのアップロード メッセージは、ダウンロード ページでファイルをアップロードします。An upload message to the recipient uploads the files at the download page when there is upload and download content.')" ></span>
        <span v-else v-html="_d('upload_message_on_download_page_upload_only', '共有するファイルをアップロードしてください', 'アップロードのみの場合は、受信者へのアップロード メッセージにより、ダウンロード ページでファイルがアップロードされます。An upload message to the recipient uploads the files at the download page when there is only an upload.')" ></span>
        <br>
      </div>
      <div class="col-2"></div>

      <div class="col-2"></div>
      <div class="col-8">
        <FileDropArea :attach_id="attachKey"  @resultError="checkResultError" :disabled="false" />
      </div> <!-- col-8 -->
      <div class="col-2"></div>
    </div> <!-- row -->

    <div class="row" >
      <div class="col-2"></div>
        <div class="col-8">
          <br>
          <br>
          <button data-cy="logout-btn" class="btn btn-primary" @click.prevent="onLogout">{{_d('bottom_logout', 'ログアウト', 'Link on the top of bottom of page.')}}</button>
          <br>
          <label></label>
        </div>  <!-- col-8 -->
      </div>  <!-- row -->

  </div>
  <div v-else class="row">
    <div class="text-center">
        <h1>{{_d('unexpected_problem_at_option_selection', '何らかの問題が発生しました', 'An unexpected problem at option selection')}}</h1>
    </div>
  </div>
</template>
<script>
import Dictionary from '@/mixins/dictionary';
import FileDropArea from '@/components/recipient-file-drop-area.vue';

// const ATTACH_STATUS = {
//   DRAFT: 10,
//   ACTIVE: 20,
//   PAUSE: 30,
//   DELETED: 40,
//   EXPIRED: 50,
// };
const ATTACH_STATUS_TEXT = {
  10: 'DRAFT',
  20: 'ACTIVE',
  30: 'PAUSE',
  40: 'DELETED',
  50: 'EXPIRED',
};
export default {
  data() {
    return {
      dict_prefix: 'recipient',
      name: 'Attach',
      loaded_data: [],
      attach_password: '',
      status_label: '',
      attach_key: '',
      page_state: 'loading',
      session_storage_key: '',
      error_message1: '',
      error_message2: '',
      list_of_files: [],
      session_key: '',
    };
  },
  mixins: [Dictionary],
  routes: [
    {
      path: '/u/:key', name: 'AttachUpload', component: this, meta: { requiresAuth: true, link_mode: 'upload' },
    },
    {
      path: '/a/:key', name: 'Attach', component: this, meta: { requiresAuth: true, link_mode: 'download' },
    },
  ],
  computed: {
    mfaAddress: {
      get() {
        return this.mfa_address;
      },
      set(v) {
        this.mfa_address = v;
      },
    },
    listOfFiles: {
      get() {
        return this.list_of_files;
      },
      set(v) {
        this.list_of_files = v;
      },
    },
    errorMessage1: {
      get() {
        return this.error_message1;
      },
      set(v) {
        this.error_message1 = v;
      },
    },
    errorMessage2: {
      get() {
        return this.error_message2;
      },
      set(v) {
        this.error_message2 = v;
      },
    },
    pageState: {
      get() {
        return this.page_state;
      },
      set(v) {
        this.page_state = v;
      },
    },
    attachKey: {
      get() {
        return this.attach_key;
      },
      set(v) {
        this.attach_key = v;
      },
    },
    allowUpload: {
      get() {
        return this.allow_upload;
      },
      set(v) {
        this.allow_upload = v;
      },
    },
    loadedData: {
      get() {
        return this.loaded_data;
      },
      set(v) {
        this.loaded_data = v;
      },
    },
    displayName() {
      return localStorage.getItem('display_name');
    },
  },
  components: {
    FileDropArea,
  },
  methods: {
    onLogout() {
      sessionStorage.removeItem(this.session_storage_key);
      this.$router.push(`/completed/${this.attach_key}`);
    },
    storageIDFormat(storage_id) {
      const style = 'badge bg-primary';
      if (storage_id === 2) {
        return `<span class='${style}'>S3</span>`;
      } if (storage_id === 3) {
        return `<span class='${style}'>Vultr</span>`;
      } if (storage_id === 4) {
        return `<span class='${style}'>R2</span>`;
      }
      return `<span class='${style}'>UNKNOWN</span>`;
    },
    getRedirection(file) {
      this.session_key = sessionStorage.getItem(this.session_storage_key) || '';
      const api_url = `/recipient/bl/${this.session_key}/${file.id}`;
      const body = {
        session_key: this.session_key,
      };
      this.$ajax.post(api_url, body, (err, result) => {
        if (err) {
          this.$notify({
            type: 'error',
            title: this._d('redirection_unexpected_error_popup_title', '予期しないエラー', 'At get download link, a popup for an unexpected error title.'),
            text: err,
          });
          return;
        }
        window.open(result.url, '_blank');
      });
    },
    buildLink(file) {
      return `/api/recipient/d/${this.session_key}/${file.id}`;
    },
    formatFileSize(size_in_bytes) {
      // If the file is bigger then 1MB then show the size in MB
      if (size_in_bytes > 1000000) {
        return `${(size_in_bytes / 1000000).toFixed(1)} MB`;
      }
      return `${(size_in_bytes / 1000).toFixed(1)} KB`;
    },
    fileNameFormat(name) {
      // if file name is bigger then 30 chars then cut showing the first 10 chars and the last 10 chars
      if (name.length > 75) {
        return `${name.substring(0, 35)}...${name.substring(name.length - 35, name.length)}`;
      }
      return name;
    },
    checkResultError(result) {
      if (result.invalid_cookie === true || result.invalid_session === true) {
        this.$notify({
          type: 'error',
          title: this._d('session_expired_error_popup_title_at_load_files', 'Your session expired or is invalid.', 'At load files, a popup for a session invalid title.'),
          text: this._d('session_expired_error_popup_text_at_load_files', 'Please add the password again.', 'At load files, a popup for a session invalid text.'),
        });
        sessionStorage.removeItem(this.session_storage_key);
        this.pageState = 'ask_for_password';
      } else if (result.not_found === true) {
        this.errorMessage1 = this._d('attachment_not_found_error_popup_title_at_load_files', 'The attachment you requested was not found.', 'At load files, a popup for a attachment not found title.');
        this.errorMessage2 = this._d('attachment_not_found_error_popup_text_at_load_files', 'Contact the sender for details.', 'At load files, a popup for a attachment not found text.');

        this.$notify({
          type: 'error',
          title: this.errorMessage1,
          text: this.errorMessage2,
        });
        this.pageState = 'error';
      } else if (result.expired === true) {
        this.errorMessage1 = this._d('file_expired_error_popup_title_at_load_files', 'The files you requested are already expired.', 'At load files, a popup for a file expired title.');
        this.errorMessage2 = this._d('file_expired_error_popup_text_at_load_files', 'Contact the sender for details.', 'At load files, a popup for a file expired text.');
        this.$notify({
          type: 'error',
          title: this.errorMessage1,
          text: this.errorMessage2,
        });
        this.pageState = 'error';
      } else if (result.file_not_found === true) {
        this.errorMessage1 = this._d('file_not_found_error_popup_title_at_load_files', 'The files you requested were not found.', 'At load files, a popup for a file not found title.');
        this.errorMessage2 = this._d('file_not_found_error_popup_text_at_load_files', 'Contact the sender for details.', 'At load files, a popup for a file not found text.');
        this.$notify({
          type: 'error',
          title: this.errorMessage1,
          text: this.errorMessage2,
        });

        this.pageState = 'error';
      } else if (result.invalid_status === true) {
        if ((result.message || '').length > 0) {
          this.errorMessage1 = this._d('invalid_status_error_popup_title_at_load_files_with_msg', 'The files you requested are in and invalid status.', 'At load files, a popup for a invalid status title.');
          this.errorMessage2 = result.message;
        } else {
          this.errorMessage1 = `${this._d('invalid_status_error_popup_title_at_load_files', 'The files you requested are in and invalid status.', 'At load files, a popup for a invalid status title.')}(${ATTACH_STATUS_TEXT[result.status]})`;
          this.errorMessage2 = this._d('invalid_status_error_popup_text_at_load_files', 'Contact the sender for details.', 'At load files, a popup for a invalid status text.');
        }
        this.$notify({
          type: 'error',
          title: this.errorMessage1,
          text: this.errorMessage2,
        });
        this.pageState = 'error';
      } else {
        this.errorMessage1 = this._d('unexpected_error_popup_title_at_load_files', '予期しないエラー', 'At load files, a popup for an unexpected error title.');
        this.errorMessage2 = this._d('unexpected_error_popup_text_at_load_files', 'Please try again', 'At load files, a popup for an unexpected error text.');

        this.$notify({
          type: 'error',
          title: this.errorMessage1,
          text: this.errorMessage2,
        });
        this.pageState = 'ask_for_password';
      }
    },
    loadFiles() {
      this.pageState = 'processing';
      const api_url = `/recipient/list_files/${this.attachKey}`;
      this.session_key = sessionStorage.getItem(this.session_storage_key) || '';
      const body = {
        session_key: this.session_key,
      };
      this.$ajax.post(api_url, body, (err, result) => {
        console.log({ err, result, body });
        if (err) {
          this.errorMessage1 = this._d('load_files_unexpected_error_popup_title', 'Unexpected error...', 'At load files page, a popup for an unexpected error title.');
          try {
            this.errorMessage2 = JSON.stringify(err);
          } catch (error) {
            this.errorMessage2 = err;
          }
          this.pageState = 'error';
          this.$notify({
            type: 'error',
            title: this.errorMessage1,
            text: this.errorMessage2,
          });
        } else if (result.success === true) {
          this.pageState = 'show_files';
          this.listOfFiles = result.files;
          this.allowUpload = result.allow_upload;
          // request the files list
        } else {
          this.checkResultError(result);
        }
      });
    },
    onSubmitPassword() {
      const api_url = `/recipient/authenticate/${this.attachKey}`;
      const body = {
        password: this.attach_password,
      };
      this.$ajax.post(api_url, body, (err, result) => {
        console.log('TEST DEBUG 240121 (367 at recipient.vue)[23:23]: ', { err, result, body });
        if (err) {
          try {
            if (result.data.invalid_status === true) {
              this.errorMessage1 = this._d('invalid_status_error_popup_title_at_login', 'The URL you requested are in and invalid status.', 'At login page, a popup for a invalid status title.');
              this.errorMessage2 = this._d('invalid_status_error_popup_text_at_login', 'Contact the sender for details.', 'At login page, a popup for a invalid status text.');
              this.pageState = 'error';
              this.$notify({
                type: 'error',
                title: this._d('unexpected_error_popup_title_at_status', '予期しないエラー', 'At login page, a popup for an unexpected error title.'),
                text: JSON.stringify(err),
              });
              setTimeout(() => {
                this.pageState = 'ask_for_password';
              }, 5000);
            } else {
              this.$notify({
                type: 'error',
                title: this._d('unexpected_error_popup_title_at_generic', '予期しないエラー', 'At login page, a popup for an unexpected error title.'),
                text: JSON.stringify(err),
              });
            }
          } catch (error) {
            this.$notify({
              type: 'error',
              title: this._d('unexpected_error_popup_title_at_cache', '予期しないエラー', 'At login page, a popup for an unexpected error title.'),
              text: err,
            });
          }
        } else if (result.valid_password === true && result.has_mfa === true) {
          console.log('TEST DEBUG 240204 (397 at recipient.vue)[21:28]: Need to do the MFA', { });
          sessionStorage.removeItem(this.session_storage_key);
          sessionStorage.setItem(`mfa_${this.session_storage_key}`, result.session_key);
          let link_mode_prefix = 'a';
          if (this.$route.meta.link_mode === 'upload') {
            link_mode_prefix = 'u';
          }
          console.log('TEST DEBUG 240204 (413 at recipient.vue)[23:53]: ', { result });
          if (result.mfa_mode === 'mail' || result.mfa_mode === 'sms') {
            console.log('TEST DEBUG 240204 (414 at recipient.vue)[23:55]: ', { });
            if (result.mfa_auth_model === 'single') { // /mfa-s/
              console.log('TEST DEBUG 240204 (416 at recipient.vue)[23:55]: single', { url: `/mfa-s/${link_mode_prefix}/${this.attachKey}` });
              // this.mfaAddress = result.mfa_single_address;
              // sessionStorage.setItem("temp_mail_"+this.session_storage_key+"", result.mfa_single_address)
              return this.$router.push(`/mfa-s/${result.mfa_mode}/${link_mode_prefix}/${this.attachKey}`);
            } if (result.mfa_auth_model === 'multi') { // /mfa-m/
              console.log('TEST DEBUG 240204 (421 at recipient.vue)[23:55]: multi', { });
              return this.$router.push(`/mfa-m/${result.mfa_mode}/${link_mode_prefix}/${this.attachKey}`);
            } if (result.mfa_auth_model === 'free') { // /mfa-f/
              console.log('TEST DEBUG 240204 (424 at recipient.vue)[23:55]: free', { });
              return this.$router.push(`/mfa-f/${result.mfa_mode}/${link_mode_prefix}/${this.attachKey}`);
            }
          } else {
            this.$notify({
              type: 'error',
              title: this._d('mfa_not_implemented', 'MFAが実装されていません', 'Message informing that MFA is not implemented'),
              text: this._d('error_mfa_not_implemented', 'エラーが発生しました。 MFAが実装されていません。', 'Message informing that an error occurred because MFA is not implemented'),
            });
          }
        } else if (result.valid_password === true && result.has_mfa === false) {
          sessionStorage.setItem(this.session_storage_key, result.session_key);
          if (result.link_mode === 'download') {
            this.loadFiles();
          } else if (result.link_mode === 'upload') {
            this.pageState = 'ready_to_upload';
          }
          // request the files list
        } else {
          // FIXME: Need to improve it to if mistake the password for X times, to block the access for X minutes.
          this.$notify({
            type: 'error',
            title: this._d('invalid_password_popup_title', '無効なパスワード', 'At login page, a popup for Invalid password error title.'),
            text: this._d('invalid_password_popup_message', 'もう一度試してください。', 'At login page, a popup for Invalid password error title.'),
          });
          this.pageState = 'ask_for_password';
        }
      });
    },
  },
  props: [],
  beforeCreate() {
    console.log(`${this.name} beforeCreate`);
  },
  created() {
    console.log(`${this.name} created`);
  },
  beforeMount() {
    console.log(`${this.name} beforeMount`);
  },
  mounted() {
    if (this.$route.params.key === 'undefined') {
      // redirect to not found page
      this.$router.push('/not_found');
    } else {
      this.attachKey = this.$route.params.key;
      this.session_storage_key = `attach_key_${this.attachKey}`;
      const session_storage_data = sessionStorage.getItem(this.session_storage_key) || false;
      if (session_storage_data === false) {
        // ask for the password
        this.pageState = 'ask_for_password';
      } else if (this.$route.meta.link_mode === 'upload') {
        // redirect to upload page
        this.pageState = 'ready_to_upload';
      } else if (this.$route.meta.link_mode === 'download') {
        // load the files
        this.loadFiles();
      }
    }
    console.log(`${this.name} mounted`);
  },
  beforeUpdate() {
    console.log(`${this.name} beforeUpdate`);
  },
  updated() {
    console.log(`${this.name} updated`);
  },
  beforeUnmount() {
    console.log(`${this.name} beforeUnmount`);
  },
  unmounted() {
    console.log(`${this.name} unmounted`);
  },
  errorCaptured() {
    console.log(`${this.name} errorCaptured`);
  },
  renderTracked() {

  },
  renderTriggered() {
    console.log(`${this.name} renderTriggered`);
  },
  activated() {
    console.log(`${this.name} activated`);
  },
  deactivated() {
    console.log(`${this.name} deactivated`);
  },
};
</script>
<style scoped>
.btn-large {
  margin: 10px 0px 10px 0px;;
  width:300px;
}
.btn-primary {
    background-color: #6666FF; /* Primary color */
    border-color: #5a62d2; /* Slightly darker shade for the border */
}

.btn-primary:hover {
    background-color: #6268e0; /* Slightly darker shade for hover */
    border-color: #4f58c2; /* Optionally, you could also darken the border color slightly on hover */
}

.download_tr:hover{
  background-color: #f7f7f7;
}

</style>
