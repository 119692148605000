<template>
  <div v-if="pageState === 'loading'" class="d-flex justify-content-center align-items-center min-vh-100">
    <div class="text-center">
        <h1 v-html="_d('msg_loading_page', '読み込み中...', 'Message informing is loading the page')"></h1>
    </div>
  </div>
  <div v-else-if="pageState === 'processing'" class="d-flex justify-content-center align-items-center min-vh-100">
    <div class="text-center">
      <h1 v-html="_d('msg_processing', '処理中...', 'Message informing is processing')"></h1>
    </div>
  </div>
  <div v-else-if="pageState === 'error'" class="d-flex justify-content-center align-items-center min-vh-100">
    <div class="text-center">
      <h1 v-html="_d('msg_error', 'エラー', 'Message informing about an error')"></h1>
        <div>{{ errorMessage1 }}<br>{{ errorMessage2 }}</div>
    </div>
  </div>
  <RecipientContainer :add_logo="true" v-else-if="pageState === 'validating'">
    validating
  </RecipientContainer>
  <RecipientContainer :add_logo="true" :center="true" v-else-if="pageState === 'no_cookie'">
    <span v-html="_d('mfa_login_ask_password','新しいセッションの MFA リンクで使用したように、ファイルにアクセスするためのパスワードをもう一度入力してください。','Message at recipient login page')"></span>
    <br>
    <br>
    <input type="password" class="form-control" id="attach_password" :placeholder="_d('mfa_password', 'パスワード', 'password to login')" v-model="attach_password"
    style="max-width: 300px;" autocomplete="off" data-lpignore="true">
    <br>
    <button class="btn btn-primary" @click.prevent="validateMfaLink(true)" v-html="_d('mfa_login_button', 'ログイン', 'button to login')"></button>
  </RecipientContainer>
  <div v-else class="row">
    <div class="text-center">
        <h1>{{_d('unexpected_problem_at_option_selection', '何らかの問題が発生しました', 'An unexpected problem at option selection')}}</h1>
    </div>
  </div>
</template>
<script>
import Dictionary from '@/mixins/dictionary';
import RecipientContainer from '@/components/recipient-container';

export default {
  data() {
    return {
      dict_prefix: 'recipient_mfa_link',
      name: 'MFA-link',
      loaded_data: [],
      attach_password: '',
      status_label: '',
      attach_key: '',
      page_state: 'loading',
      session_storage_key: '',
      error_message1: '',
      error_message2: '',
      list_of_files: [],
      session_key: '',
      mfa_address: '',
      link_mode_prefix: '',
      mfa_mode: '',
      received_code: '',
      return_url: '',
    };
  },
  components: {
    RecipientContainer,
  },
  mixins: [Dictionary],
  routes: [
    {
      path: '/mfa-validate/:mfa_session_id/:mfa_id/:code', name: 'ValidateLink', component: this, meta: { requiresAuth: true, mfa_mode: 'validate_link' },
    },
  ],
  computed: {
    receivedCode: {
      get() {
        return this.received_code;
      },
      set(v) {
        this.received_code = v;
      },
    },
    mfaAddress: {
      get() {
        return this.mfa_address;
      },
      set(v) {
        this.mfa_address = v;
      },
    },
    errorMessage1: {
      get() {
        return this.error_message1;
      },
      set(v) {
        this.error_message1 = v;
      },
    },
    errorMessage2: {
      get() {
        return this.error_message2;
      },
      set(v) {
        this.error_message2 = v;
      },
    },
    pageState: {
      get() {
        return this.page_state;
      },
      set(v) {
        this.page_state = v;
      },
    },
    attachKey: {
      get() {
        return this.attach_key;
      },
      set(v) {
        this.attach_key = v;
      },
    },
  },
  methods: {

    onLogout() {
      sessionStorage.removeItem(this.session_storage_key);
      this.$router.push(`/completed/${this.attach_key}`);
    },
    validateMfaLink(with_password = false) {
      console.log('TEST DEBUG 240208 (251 at mfa.vue)[17:19]:at validateMfaLink ', { });

      const { mfa_session_id } = this.$route.params;
      const { mfa_id } = this.$route.params;
      const { code } = this.$route.params;
      const api_url = `/recipient/validate_link/${this.attachKey}`;
      const body = {
        mfa_session_id,
        mfa_id,
        code,
      };
      if (with_password) {
        body.attach_password = this.attach_password;
      }
      this.$ajax.post(api_url, body, (err, result) => {
        console.log('TEST DEBUG 240208 (163 at mfa-link.vue)[17:38]: ', { err, result });
        if (result?.data?.cookie_invalid === true) {
          this.pageState = 'no_cookie';
          return;
        }
        if (result?.validated === false) {
          this.pageState = 'error';
          this.errorMessage1 = this._d('invalid_link', '無効なリンク', 'Message informing the link is invalid');
          this.errorMessage2 = this._d('invalid_or_expired_link', 'リンクが無効または期限切れです、もう一度お試しください。', 'Message informing the link is invalid or expired and the user should try again');
          return;
        }
        if (result?.validated === true) {
          this.pageState = 'processing';

          this.session_storage_key = `attach_key_${result.attach_key}`;
          console.log('TEST DEBUG 240208 (160 at mfa-link.vue)[19:51]: ', { session_storage_key: this.session_storage_key, attach_key: result.attach_key, session_key: result.session_key });
          sessionStorage.setItem(this.session_storage_key, result.session_key);

          this.$router.push(result?.new_url);
        } else {
          this.pageState = 'error';
          this.errorMessage1 = this._d('unexpected_error_at_mfa_validation', '予期しないエラー', 'Message informing about an Unexpected error');
          this.errorMessage2 = this._d('unexpected_error_try_again', '予期しないエラーが発生しました、もう一度お試しください。', 'Message informing an unexpected error occurred and the user should try again');
        }
      });
    },
  },
  props: [],
  beforeCreate() {
    console.log(`${this.name} beforeCreate`);
  },
  created() {
    console.log(`${this.name} created`);
  },
  beforeMount() {
    console.log(`${this.name} beforeMount`);
  },
  mounted() {
    this.pageState = 'validating';
    this.validateMfaLink(false);

    console.log(`${this.name} mounted`);
  },
  beforeUpdate() {
    console.log(`${this.name} beforeUpdate`);
  },
  updated() {
    console.log(`${this.name} updated`);
  },
  beforeUnmount() {
    console.log(`${this.name} beforeUnmount`);
  },
  unmounted() {
    console.log(`${this.name} unmounted`);
  },
  errorCaptured() {
    console.log(`${this.name} errorCaptured`);
  },
  renderTracked() {

  },
  renderTriggered() {
    console.log(`${this.name} renderTriggered`);
  },
  activated() {
    console.log(`${this.name} activated`);
  },
  deactivated() {
    console.log(`${this.name} deactivated`);
  },
};
</script>
<style scoped>
.btn-large {
  margin: 10px 0px 10px 0px;;
  width:300px;
}
.btn-primary {
    background-color: #6666FF; /* Primary color */
    border-color: #5a62d2; /* Slightly darker shade for the border */
}

.btn-primary:hover {
    background-color: #6268e0; /* Slightly darker shade for hover */
    border-color: #4f58c2; /* Optionally, you could also darken the border color slightly on hover */
}

.download_tr:hover{
  background-color: #f7f7f7;
}

</style>
