import { createRouter, createWebHistory } from 'vue-router';

const routes = [];
// Define here the name of each file that should be part to the router
[
  require('../views/recipient'),
  require('../views/mfa'),
  require('../views/mfa-link'),
  require('../views/not_found'),
  require('../views/completed'),
].forEach((file) => {
  console.log('TEST DEBUG 231015 (17 at index.js)[18:06]: ', { file });
  file.default.routes.forEach((r) => {
    console.log('TEST DEBUG 231015 (19 at index.js)[18:06]: ', { r });
    r.component = file.default;
    routes.push(r);
  });
});

// Here it configure the routes
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Here it authenticate the cookies, adust it based on the login rules
// const checkCookies = () => {
//   let found = 0;
//   const s = document.cookie.split(';');
//   s.forEach((c) => {
//     const kv = c.trim().split('=');
//     if (kv[0] === 'session_key' && kv[1].length > 70) {
//       found += 1;
//     }
//     if (kv[0] === 'session_user' && kv[1].length > 0) {
//       found += 1;
//     }
//   });
//   return found === 2;
// };

// Before it navigate to each page, it will run this.
// If the requiresAuth be set then it will check the cookies,
// if the cookies don't be valid, then it will redirect to the
// expired page.

router.beforeEach((to, from, next) => {
  // console.log('TEST DEBUG 231022 (53 at index.js)[11:05]: ', {  });
  // if (to.meta.requiresAuth === true && checkCookies() === false) {
  //   console.log('TEST DEBUG 231022 (55 at index.js)[11:05]: ', {  });
  //   next({
  //     path: '/expired',
  //   });
  // } else {
  //   console.log('TEST DEBUG 231022 (60 at index.js)[11:05]: ', {  });
  //   console.log('router accessing page', to.name);
  next();
  // }
});

export default router;
